// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'

export const getTasks = createAsyncThunk('appTodo/getTasks', async params => {
  const response = await axios.post('/events/get-all-todos', params)

  return {
    params,
    data: response.data.events
  }
})

export const addTask = createAsyncThunk('appTodo/addTask', async (task, { dispatch, getState }) => {
  const response = await axios.post('/events/create', task)
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const updateTask = createAsyncThunk('appTodo/updateTask', async (task, { dispatch, getState }) => {
  const response = await axios.post('/events/update', task)
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const updateStatus = createAsyncThunk('appTodo/updateStatus', async (task, { dispatch, getState }) => {
  const response = await axios.post('/events/updatestatus', task)
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const deleteTask = createAsyncThunk('appTodo/deleteTask', async (data, { dispatch, getState }) => {
  const response = await axios.post('/events/delete', data)
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const appTodoSlice = createSlice({
  name: 'appTodo',
  initialState: {
    tasks: [],
    selectedTask: {},
    params: {
      filter: '',
      q: '',
      sort: '',
      tag: ''
    }
  },
  reducers: {
    reOrderTasks: (state, action) => {
      state.tasks = action.payload.data
    },
    selectTask: (state, action) => {
      state.selectedTask = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getTasks.fulfilled, (state, action) => {
      state.tasks = action.payload.data
      state.params = action.payload.params
    })
  }
})

export const { reOrderTasks, selectTask } = appTodoSlice.actions

export default appTodoSlice.reducer
